import { useRef } from 'react'
import { RadioCircleFilledIcon, RadioCircleUnfilledIcon } from '../icons'
import { TypographyParagraph } from '../typography'
import clsx from 'clsx'

const RadioInput = ({
	size = 'small',
	radioSize,
	name = 'Radio Input',
	current = false,
	isInactive = false,
	invertTheme = false,
	onClick,
}: {
	size: 'small' | 'large'
	radioSize?: 'small' | 'large'
	name: string
	current: boolean
	isInactive?: boolean
	invertTheme?: boolean
	onClick?: () => void
}) => {
	const ref = useRef(null)
	return (
		<div
			className={clsx(
				isInactive ? 'cursor-not-allowed' : 'cursor-pointer',
				'flex items-center py-2 sm:my-2 sm:py-0',
			)}
		>
			<input
				ref={ref}
				id={name}
				name={name}
				type="radio"
				defaultChecked={current}
				className={clsx('hidden')}
				onClick={onClick}
			/>
			{/* @ts-ignore */}
			<label
				htmlFor={name}
				className={clsx(
					isInactive ? 'cursor-not-allowed' : 'cursor-pointer',
					'flex gap-x-2',
					(() => {
						// Text color logic
						if (isInactive) {
							return invertTheme
								? 'text-textInactiveDark dark:text-textInactiveLight'
								: 'text-textInactiveLight dark:text-textInactiveDark'
						} else {
							const baseColor = invertTheme
								? 'text-textSecondaryDark dark:text-textSecondaryLight'
								: 'text-textPrimaryLight dark:text-textSecondaryDark'
							const currentColor = invertTheme
								? 'text-textPrimaryDark dark:text-textPrimaryLight'
								: 'text-textPrimaryLight dark:text-textPrimaryDark'
							// Apply current state color if needed
							return current ? currentColor : baseColor
						}
					})(),
					'ml-2 block',
				)}
			>
				<div className="group flex items-center">
					{current ? (
						<RadioCircleFilledIcon
							size={radioSize || size}
							invertTheme={invertTheme}
						/>
					) : (
						<RadioCircleUnfilledIcon
							size={radioSize || size}
							invertTheme={invertTheme}
						/>
					)}
				</div>
				<TypographyParagraph
					size={size === 'small' ? 'xSmall' : 'medium'}
					isBold={true}
				>
					{name}
				</TypographyParagraph>
			</label>
		</div>
	)
}

export default RadioInput
